/**
 * Cart functionality
 */

/** @function */
var cart = (function() {
	var $base = $(this);

    /**
     * Initialization
     */
    $base.init = function() {
   		$base.addCheckoutEvents();
        $base.setupAffix();
        $base.updateCart();
        $base.initStripe();
    };

    /**
   	 * Add the check out action to the click event on the applicable links
   	 */
   	$base.addCheckoutEvents = function() {
   		$('a.cart-checkout').each(function() {
            $(this).click(function(e) {
   				e.preventDefault();
                $base.checkout();
   			});
   		});
   	}; // end function base.addMenuEditEvent

    /**
     * Set up the cart affix
     */
    $base.setupAffix = function() {
        if ($('#cart-large').length)
        {
            $('#cart-large').affix({
                offset: {
                    top: $('#cart-large').offset().top
                }
            });
        }
    };

    /**
     * Update the cart, optionally performing a cart action (add, delete, etc.)
     * @param {object} [params]
     * @param {(null|function)} [success] handler for a successful ajax call
     * @param {(null|function)} [failure] handler for a failed ajax call
     */
    $base.updateCart = function(params, success, failure) {
        if (! $('#cart-contents').length)
        {
            return;
        }

        $base.submitting = true;

        $('a.cart-checkout').attr('disabled', true);
        $('.cart-count').html('<img src="/img/ajax-loader.gif" width="12" />');
        $('#cart-contents').load(
            '/cart/contents',
            params || {},
            function(response, status) {
                $base.submitting = false;
                $('a.cart-checkout').attr('disabled', false);

                if (status == 'success' && success)
                {
                    // fire the success handler
                    success();
                }
                else if (failure)
                {
                    // fire the failure handler
                    failure();
                }
            }
        );
    }; // end function $base.updateCart

    // total cart price
    $base.total_price = 0;

    $base.setTotalPrice = function(price) {
        $base.total_price = price;
    }

    /**
     * Update the totals displayed on the screen
     * @param {integer} qty
     * @param {float} total
     */
    $base.updateTotals = function(qty, total) {
        if (qty == 0)
        {
            $('.cart-count').html('Empty');
            $('#cart-buttons').hide();
            $base.setTotalPrice(0);
        }
        else
        {
            $('.cart-count').html(qty + ' / $' + total.toFixed(2));
            $('#cart-buttons').removeClass('hidden').show();
            $base.setTotalPrice(total);
        }
    }

    /**
     * Add an item to the cart
     * @param {integer} menu_id
     * @param {integer} student_id
     */
    $base.addItem = function(menu_id, student_id) {
        if ($base.isSubmitting()) return;

        // disable the add button
        $base.setInCart(menu_id, student_id, true);

        // add the menu+student to the cart
        $base.updateCart({
                'action': 'add',
                'menu_id': menu_id,
                'student_id': student_id
            },
            $.noop,
            function() {
                // there was an error
                // show the button again
                $base.setInCart(menu_id, student_id, false);
            }
        );
    }; // end function $base.addItem

    /**
     * Add multiple items to the cart
     * @param {array} menu_ids
     * @param {integer} student_id
     */
    $base.addItems = function(menu_ids, student_id) {
        if ($base.isSubmitting()) return;

		// disable the add buttons
		$.each(menu_ids, function(_, menu_id) {
			$base.setInCart(menu_id, student_id, true);
		});

        // add the menus+student to the cart
        $base.updateCart({
                'action': 'add',
                'menu_ids': menu_ids.join(','),
                'student_id': student_id
            },
            $.noop,
            function() {
                // there was an error
                // show the button again
				$.each(menu_ids, function(_, menu_id) {
					$base.setInCart(menu_id, student_id, false);
				});
            }
        );
    }; // end function $base.addItem

    /**
     * Remove an item from the cart
     * @param {integer} cart_item_id
     * @param {integer} menu_id
     * @param {integer} student_id
     * @param {object} link
     */
    $base.removeItem = function(cart_item_id, menu_id, student_id, link) {
        if ($base.isSubmitting()) return;

        $(link).removeClass().html('<img src="/img/ajax-loader.gif" width="15" alt="Please wait..." />');

        $base.updateCart({
                'action': 'delete',
                'cart_item_id': cart_item_id
            },
            function() {
                // verify that the button exists on the page and show it if it does
                // (if they remove a cart item from a different month then it won't be)
                $base.setInCart(menu_id, student_id, false);
            }
        );
    };

    /**
     * Change a cart button to reflect if it's in the cart or not
     * @param {int} menu_id
     * @param {int} student_id
     * @param {boolean} in_cart
     */
    $base.setInCart = function(menu_id, student_id, in_cart) {
        var $btn = $('#add-btn-' + menu_id + '-' + student_id);

        if ($btn)
        {
            if (in_cart)
            {
                $btn.removeClass('not-in-cart').addClass('in-cart');
            }
            else
            {
                $btn.removeClass('in-cart').addClass('not-in-cart');
            }
        }
    };

    // is the last action submitting?
    $base.submitting = false;

    /**
     * Show an alert if the last action is submitting
     * This should be used like this in functions that perform ajax actions:
     *
     * if ($base.isSubmitting()) return;
     *
     * @returns {boolean}
     */
    $base.isSubmitting = function() {
        if ($base.submitting)
        {
            alert('Please hold while your last action is completed.');
            return true;
        }

        return false;
    };

    $base.stripe = null;
    $base.stripe_key = $('meta[name="stripe-pk"]').attr('content');

    $base.initStripe = function() {
        if (! $('a.cart-checkout').length)
        {
            // no checkout buttons on this page
            // no need to initialize Stripe
            return;
        }
        else if (typeof(StripeCheckout) == 'undefined')
        {
            alert('Stripe init error: StripeCheckout was not found');
            return;
        }
        else if ($base.stripe_key == null || ! $base.stripe_key.length || $base.stripe_key == 'StripePublicKey')
        {
            alert('Stripe init error: public key is not set');
            return;
        }

        // initialize the Stripe handler
        $base.stripe = StripeCheckout.configure({
            key: $base.stripe_key,
            token: $base.processStripePayment,
            billingAddress: true,
            image: '/img/stripe-logo.png'
        });

        // close the Stripe window on page navigation
        $(window).on('popstate', function() {
            $base.stripe.close();
        });
    } // end function $base.initStripe

    /**
     * Open the Stripe checkout window
     */
    $base.checkout = function() {
        if ($base.isSubmitting()) return;

        if ($base.stripe == null)
        {
            alert('Stripe checkout error: Stripe is not initialized');
            return;
        }

        $base.stripe.open({
            name: 'Thomas Edison Charter Schools',
            description: 'Lunch Menu Purchase',
            amount: $base.total_price * 100
        });
    }; // end function $base.checkout

    /**
     * Process the Stripe payment
     * This creates a form, submits it, and /cart/checkout will handle it from there
     * @param {object} token
     */
    $base.processStripePayment = function(token) {
        $('<div/>')
            .html('<p><img src="/img/ajax-loader.gif" alt="Please hold..." /> Please hold while your lunch menu purchase is processed...</p>')
            .dialog({
                dialogClass: 'no-close',
                title: 'Completing Your Purchase',
                modal: true,
                width: 350
            });

        $('<form/>', {method: 'post', action: '/cart/checkout'})
            .append($('<input/>', {type: 'hidden', name: '_token', value: $('meta[name="csrf-token"]').attr('content')}))
            .append($('<input/>', {type: 'hidden', name: 'amount', value: $base.total_price}))
            .append($('<input/>', {type: 'hidden', name: 'data', value: JSON.stringify(token)}))
            .appendTo('body')
            .submit();
    };

	return $base;
})();

$(document).ready(cart.init);
