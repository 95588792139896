/**
 * Add delete/inactivate/etc. confirmation dialogs to all applicable inputs
 */

$(document).ready(function() {
	$('input[type=submit][data-delete]').each(function(idx, obj) {
        var data = $(obj).data('delete').split(':'),
            name = data[0],
            action = data[1],
            this_that = data[2] || 'this',
            ucname = name.replace(/\b[a-z]/g, function(letter) { return letter.toUpperCase(); }),
            ucaction = action.replace(/\b[a-z]/g, function(letter) { return letter.toUpperCase(); }),
            $form = $(obj.form);

        $(obj).click(function(e) {
            e.preventDefault();

            openConfirmDialog(
                ucaction + ' ' + ucname + '?',
                'Are you sure that you would like to ' + action + ' ' + this_that + ' ' + name + '?',
                function() {
                    $form.submit();
                }
            );
        });
	});
});
