/**
 * Open confirmation dialogs
 */

/**
 * Open a modal confirmation dialog
 * @param {string} [title=Confirmation]
 * @param {string} msg
 * @param {function} yes function to execute if they press "Yes"
 */
function openConfirmDialog(title, msg, yes)
{
    $('#confirm-dialog')
        .html('<p>' + msg + '</p>')
        .dialog({
            title: title || 'Confirmation',
            resizable: false,
            modal: true,
            //width: 400,
            buttons: {
                Yes: function() {
                    yes();
                    $(this).dialog('close');
                },
                No: function() {
                    $(this).dialog('close');
                }
            }
        });
}