/**
 * Menu/calendar functionality
 */

/** @function */
var menu = (function() {
	var $base = $(this);

	// MediumEditor instance
	$base.editor = null;

	// default the editor contents to this so that it's centered by default
	$base.empty_contents = '<div style="text-align:center"><br></div>';

    /**
     * Initialization
     */
    $base.init = function() {
		$base.addMenuEditEvents();
		$base.addDateEvents();
		$base.addAddAllEvents();
		$base.addSmallCalendarEvents();
        $base.initStripe();
    };

	/**
	 * Add the "open edit dialog" action to the click event on the applicable links
	 */
	$base.addMenuEditEvents = function() {
		$('a.menu-edit').each(function() {
			var $link = $(this);
			$link.click(function(e) {
				e.preventDefault();
				$('#menu-dialog')
					.html('Please hold...')
					.load($link.attr('href'), $base.menuLoaded)
					.dialog({
						title: 'Update Menu - Please Hold',
						width: Math.min(600, $(window).width() - 50),
						height: Math.min(600, $(window).height() - 100),
						resizable: false,
						modal: true
					});
			});
		});
	}; // end function base.addMenuEditEvents()

	/**
	 * Attach the "save menu" handler to the menu edit form submit button
	 * and create a new MediumEditor
	 */
	$base.menuLoaded = function() {
		$('#menu-dialog').dialog('open');
		$('#menu-dialog').find('input[type="submit"]').click($base.saveMenu);
		$('#menu-dialog').scrollTop(0);
		$base.createEditor();
	};

	/**
	 * Set up the MediumEditor instance
	 */
	$base.createEditor = function() {
		if ($('#menu-dialog textarea').first().val().length == 0)
		{
			$('#menu-dialog textarea').val($base.empty_contents);
		}

		$base.editor = new MediumEditor('#menu-dialog textarea', {
			toolbar: {
				buttons: ['bold', 'italic', 'underline', 'justifyLeft', 'justifyCenter', 'justifyRight']
			},
			imageDragging: false,
			anchorPreview: false,
			placeholder: { text: 'Enter the menu text. If there is no school you can leave this blank.' },
			keyboardCommands: false,
			disableDoubleReturn: true,
			buttonLabels: 'fontawesome'
		});
	};

	/**
	 * Set the title of the menu edit dialog
	 * @param {string} title
	 */
	$base.setTitle = function(title) {
		$('#menu-dialog').dialog('option', 'title', title);
	};

	/**
	 * Close the menu edit dialog
	 */
	$base.closeDialog = function() {
		$base.editor.destroy();
		$('#menu-dialog').dialog('close');
	};

	/**
	 * Update the text of a single day
	 * @param {integer} day
	 * @param {string} text
	 */
	$base.setDayText = function(day, text) {
		$('#menu-day-' + day).html(text);
	}

	/**
	 * Delete a day's menu
	 * @param {integer} month
	 * @param {integer} day
	 * @param {integer} year
	 */
	$base.deleteDay = function(month, day, year) {
		$('#delete-dialog')
			.html('Are you sure that you would like to delete the menu on ' + month + '/' + day + '/' + year + '?')
			.dialog({
				modal: true,
				buttons: {
					'Yes': function() {
						// update the text, remove the buttons, and delete the day's menu
						$(this).html('Please wait...');
						$(this).dialog('option', 'buttons', []);
						$.ajax({
							url: '/menu/' + month + '/' + day + '/' + year,
							type: 'DELETE',
							success: function() {
								// clear the day's text and close the dialog
								$base.setDayText(day, '');
								$('#delete-dialog').dialog('close');
							}
						})
					},
					'No': function() {
						$(this).dialog('close');
					}
				} // end dialog buttons
			}); // end .dialog() call
	} // end function base.deleteDay()

	/**
	 * Clear the menu editor
	 */
	$base.clearMenu = function() {
		$base.editor.destroy();
		$('#menu-dialog textarea').val($base.empty_contents);
		$base.createEditor();
	}

	/**
	 * Save the menu
	 * This is called when the submit button is clicked in the menu edit dialog
	 * @param {object} e click event from edit button
	 */
	$base.saveMenu = function(e) {
		e.preventDefault();

		$('#form-errors').html('');
		var $form = $($('#menu-dialog').find('form')[0]);
		$base.editor.destroy();

		if ($('#menu-dialog textarea').val() == $base.empty_contents)
		{
			// they didn't change anything
			// clear the contents
			$('#menu-dialog textarea').val('');
		}

		var post = $.post($form.attr('action'), $form.serialize(), function(response) {
			// close the dialog and upate the day's menu text
			$('#menu-dialog').dialog('close');
			$base.setDayText(response.day, response.text);
		});
		post.fail(function(data) {
			var errors = '<div class="alert alert-danger">There were errors with your input:<br /><ul>';

			$.each(data.responseJSON, function(key, value) {
				errors += '<li>' + value[0] + '</li>';
			});

			errors += '</ul></div>';

			$base.createEditor();
			$('#form-errors').html(errors);
			$('#menu-dialog').scrollTop(0);
		});
	}; // end function base.saveMenu()

	/**
	 * Convert the purchase date fields to date pickers
	 */
	$base.addDateEvents = function() {
		$('.menu-purchase-date').datepicker({
			dateFormat: 'yy-mm-dd',
			numberOfMonths: 1,
			minDate: typeof(min_date) != 'undefined' ? min_date : '',
			maxDate: typeof(max_date) != 'undefined' ? max_date : '',
			hideIfNoPrevNext: true,
			appendText: 'yyyy-mm-dd'
		});
	};

	/**
	 * Add the click events to the "+ All" buttons
	 */
	$base.addAddAllEvents = function() {
		$('#add-all-buttons a.add-all').click(function() {
			// add every non-added/purchased date to the selection and update the totals
			var menu_ids = [];

			$('.menu-calendar .student-' + $(this).data('id') + '.not-in-cart').each(function() {
				menu_ids.push($(this).data('menu'));
			})

			if (menu_ids.length)
			{
				// add the dates to the cart
				cart.addItems(menu_ids, $(this).data('id'));
			}
		});
	}

    /****************************
     * Small calendar functions *
     ****************************/

    /**
     * Add calendar events to the small calendar and initialize the form
     */
    $base.addSmallCalendarEvents = function() {
        if (! $('#payment-form').length && ! $('#edit-form').length)
        {
            return;
        }

        if ($('#payment-form').length)
        {
            // they're creating a purchase

            // toggle dates as selected
            // allow multiple dates to be selected
            $('td.menu-addable').click(function() {
                $base.toggleDay(this, true);
            });

            // set the payment method
            $('input:radio[name=method]').click(function() {
                $base.setPaymentMethod($(this).val());
            });

            $('#payment-form').submit(function(e) {
                if ($base.getPaymentMethod() == 'card')
                {
                    // open the Stripe checkout window
                    e.preventDefault();
                    $base.openStripeCheckout();
                }
            });

            $('.small-menu-calendar a.add-all').click(function() {
				// add every non-added/purchased date to the selection and update the totals
				$('#month-' + $(this).data('month') + ' td.menu-addable:not(.menu-added)').each(function() {
					$(this).addClass('menu-added');
				})
				$base.updateTotals();
			});

            // set the initial payment method
            $base.setPaymentMethod($base.getPaymentMethod());

            // update the totals
            $base.updateTotals();
        }
        else if ($('#edit-form').length)
        {
            // they're editing a purchase

            // toggle dates as selected
            // only allow a single date to be selected
            $('td.menu-addable').click(function() {
                $base.toggleDay(this, false);
            });

            // select the initial date of the purchase
            $base.toggleDay($('#date' + $('#old-date').val()), false);
        }
    };

    /**
     * Toggle a day as added/removed
     * @param {object} cell
     */
    $base.toggleDay = function(cell, allow_multiple) {
        if (! allow_multiple)
        {
            $('td.menu-addable').removeClass('menu-added');
        }

        $(cell).toggleClass('menu-added');
        $base.updateTotals();
    };

    /**
     * Show any additional info fields
     * @param {string} method
     */
    $base.setPaymentMethod = function(method) {
        if (! $('#info-fields-' + method + ':visible').length)
        {
            $('#payment-form input[type=text]:visible').val('');
        }

        $('.info-fields').hide();
        $('.info-fields-' + method).show();
        $('#payment-form input[type=submit]').val(method=='card' ? 'Process Card' : 'Save Purchase')
    }

    /**
     * Return the current payment method
     * @returns {string}
     */
    $base.getPaymentMethod = function() {
        return $('input:radio[name=method]:checked').val();
    };

    /**
     * Update the quantity and total price
     */
    $base.updateTotals = function() {
        // save the selected dates (this is in the create and edit forms)
        $('#days').val($('td.menu-added').map(function() { return $(this).data('date'); }).get().join(','));

        if (! $('#payment-form').length)
        {
            // not on the create form
            return;
        }

        var qty = $('td.menu-added').length,
            price_each = $('#total-price').data('price-each') * 1,
            total_price = qty * price_each;

        $('#total-qty').html(qty);
        $('.total-price').html(total_price.toFixed(2));
        $('#amount').val(total_price.toFixed(2));
        $('#payment-form')[total_price == 0 ? 'hide' : 'show']();
    };

    /******************
     * Stripe payment *
     ******************/

    $base.stripe = null;
    $base.stripe_key = $('meta[name="stripe-pk"]').attr('content');

    $base.initStripe = function() {
        if (! $('#payment-form').length)
        {
            return;
        }
        else if (typeof(StripeCheckout) == 'undefined')
        {
            alert('Stripe init error: StripeCheckout was not found');
            return;
        }
        else if ($base.stripe_key == null || ! $base.stripe_key.length || $base.stripe_key == 'StripePublicKey')
        {
            alert('Stripe init error: public key is not set');
            return;
        }

        // initialize the Stripe handler
        $base.stripe = StripeCheckout.configure({
            key: $base.stripe_key,
            token: $base.processStripePayment,
            billingAddress: true,
			allowRememberMe: false,
            image: '/img/stripe-logo.png'
        });

        // close the Stripe window on page navigation
        $(window).on('popstate', function() {
            $base.stripe.close();
        });
    } // end function $base.initStripe

    /**
     * Open the Stripe checkout window
     */
    $base.openStripeCheckout = function() {
        if ($base.stripe == null)
        {
            alert('Stripe checkout error: Stripe is not initialized');
            return;
        }

        $base.stripe.open({
            name: 'Thomas Edison Charter Schools',
            description: 'Lunch Menu Purchase',
            amount: $('#amount').val() * 100
        });
    }; // end function $base.checkout

    /**
     * Process the Stripe payment, add the data to the form, and submit it
     * @param {object} token
     */
    $base.processStripePayment = function(token) {
        $('<div/>')
            .html('<p><img src="/img/ajax-loader.gif" alt="Please hold..." /> Please hold while the lunch menu purchase is processed...</p>')
            .dialog({
                dialogClass: 'no-close',
                title: 'Completing Your Purchase',
                modal: true,
                width: 350
            });

        $('#payment-form')
            .append($('<input/>', {type: 'hidden', name: 'data', value: JSON.stringify(token)}))
            .unbind('submit')
            .trigger('submit');
    };

    return $base;
})();

$(document).ready(menu.init);
